import React, { useState } from 'react';
import { Container, Row, Col, Spinner, Image } from 'react-bootstrap';
import RICIBs from 'react-individual-character-input-boxes';
import logo250 from './logo250.png'; // Ensure this path points to the file in the src directory

const Verification = ({ phone, onVerified }) => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleOutput = async (value) => {
    setCode(value);
    if (value.length === 6) {
      setLoading(true);
      try {
        const response = await fetch('/verify-code', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ phoneNumber: `+${phone}`, code: value }),
        });

        const data = await response.json();
        setLoading(false);

        if (data.status === 'approved') {
          onVerified();
        } else {
          setError('Invalid code, please try again.');
        }
      } catch (error) {
        setLoading(false);
        setError('Failed to verify code.');
      }
    }
  };

  return (
    <Container fluid className="vh-100 d-flex justify-content-center align-items-center">
      <Row>
        <Col className="d-flex flex-column align-items-center">
          <Image src={logo250} alt="Logo" className="mb-4" />
          <RICIBs
            amount={6}
            autoFocus
            handleOutputString={handleOutput}
            inputProps={{
              type: 'tel',
              inputMode: 'numeric',
              pattern: '[0-9]*',
              className: 'verification-box',
              style: { width: '2.5rem', height: '2.5rem', margin: '0.5rem', textAlign: 'center', fontSize: '1.5rem' },
            }}
          />
          {loading && <Spinner animation="border" />}
          {error && (
            <div className="text-danger mt-3">
              {error}
              <button onClick={() => { setCode(''); setError(''); }}>Retry</button>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Verification;
