import React from 'react';
import { Spinner, Container, Row, Col } from 'react-bootstrap';
import logo192 from './logo192.png'; // Ensure this path points to the file in the src directory

const Upload = ({ onUpload, isUploading, fileName, numPages, showLogo = true }) => {
  return (
    <Container className="text-center mb-3">
      <Row>
        <Col className="d-flex flex-column align-items-center">
          {showLogo && <img src={logo192} alt="Logo" className="mb-4" />}
          <label className="btn btn-dark">
            Select a pdf plan to upload
            <input 
              type="file" 
              accept="application/pdf" 
              onChange={onUpload} 
              className="form-control d-none"
            />
          </label>
          {isUploading && (
            <div className="d-flex flex-column align-items-center mt-3">
              <Spinner animation="border" variant="primary" className="ml-3" />
            </div>
          )}
          {fileName && numPages > 0 && (
            <div className="mt-3">
              File: {fileName} | Pages: {numPages}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Upload;
