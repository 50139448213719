import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Container, Row, Col, Spinner, Image } from 'react-bootstrap';
import logo192 from './logo192.png'; // Make sure the path to logo192.png is correct

const Login = ({ onPhoneSubmit }) => {
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePhoneChange = (value) => {
    setPhone(value);
    // Check for a minimum length including the country code (+1)
    if (value.length >= 11) {
      handleSubmit(value);
    }
  };

  const handleSubmit = async (phone) => {
    setLoading(true);
    try {
      const response = await fetch('/send-verification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber: `+${phone}` }),
      });

      const data = await response.json();
      setLoading(false);

      if (data.status === 'pending') {
        onPhoneSubmit(phone);
      } else {
        alert('Failed to send verification code');
      }
    } catch (error) {
      setLoading(false);
      alert('Failed to fetch');
    }
  };

  return (
    <Container fluid className="vh-100 d-flex justify-content-center align-items-center">
      <Row>
        <Col className="d-flex flex-column align-items-center">
          <Image src={logo192} alt="Logo" className="mb-4" />
          <PhoneInput
            country={'us'}
            value={phone}
            onChange={handlePhoneChange}
            inputStyle={{ width: '250px' }}
            inputProps={{
              autoFocus: true,
            }}
          />
          {loading && <Spinner animation="border" />}
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
