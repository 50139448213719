import React, { useEffect, useState } from 'react';
import loadingGif from './json.gif'; // Ensure you have a json.gif file in the src directory

const Convert2Json = () => {
  const [showContinueButton, setShowContinueButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContinueButton(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleContinue = () => {
    window.location.href = 'https://copilot.buildgpt.xyz';
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 position-relative" style={{ backgroundColor: '#f9f9f9' }}>
      <div className="gif-container position-relative">
        <img src={loadingGif} alt="Loading..." className="loading-gif" />
        <div className="overlay-cv2json">
          <div className="overlay-message">
            {/* <p>After the objects and their coordinates are extracted, we convert the data to our "GPTJSON" format optimized for LLMs and BIM</p> */}
            {showContinueButton && (
              <button onClick={handleContinue} className="btn btn-light btn-sm mt-3">Continue</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Convert2Json;
