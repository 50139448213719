import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Navbar, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Login';
import Verification from './Verification';
import Upload from './Upload';
import Thumbnails from './Thumbnails';
import Chat from './Chat';
import ComputerVision from './ComputerVision';
import Convert2Json from './Convert2Json';

const App = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [step, setStep] = useState('login');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    console.log('Checking authentication status from local storage');
    const authenticated = localStorage.getItem('authenticated');
    const savedStep = localStorage.getItem('step');
    const savedImages = JSON.parse(localStorage.getItem('images'));
    const savedFileName = localStorage.getItem('fileName');
    const savedNumPages = localStorage.getItem('numPages');
    const savedCurrentPage = localStorage.getItem('currentPage');
    const savedPhone = localStorage.getItem('phone');

    if (authenticated) {
      console.log('User is authenticated, setting state from local storage');
      setStep(savedStep || 'upload');
      setImages(savedImages || []);
      setFileName(savedFileName || '');
      setNumPages(savedNumPages || 0);
      setCurrentPage(savedCurrentPage || 0);
      setPhone(savedPhone || '');
    }
  }, []);

  useEffect(() => {
    console.log('Updating local storage with current state');
    localStorage.setItem('step', step);
    localStorage.setItem('images', JSON.stringify(images));
    localStorage.setItem('fileName', fileName);
    localStorage.setItem('numPages', numPages);
    localStorage.setItem('currentPage', currentPage);
    localStorage.setItem('phone', phone);
  }, [step, images, fileName, numPages, currentPage, phone]);

  const onUpload = async (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    const formData = new FormData();
    formData.append('pdf', file);
    setIsUploading(true);

    try {
      const response = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      console.log('Response data:', response.data.images);
      setImages(response.data.images);
      setNumPages(response.data.numPages);
      setIsUploading(false); // Reset progress bar
      event.target.value = null;
    } catch (error) {
      console.error('Error uploading file', error.response ? error.response.data : error.message);
      setIsUploading(false); // Reset progress bar
    }
  };

  const handleClick = (image) => {
    console.log('Image clicked:', image);
    setSelectedImage(image);
    setCurrentPage(image.pageNumber);
  };

  const handleSubmit = () => {
    console.log('Submit clicked:', selectedImage);
    setStep('computerVision');
  };

  const handlePhoneSubmit = (phone) => {
    setPhone(phone);
    setStep('verification');
  };

  const handleVerified = () => {
    localStorage.setItem('authenticated', 'true');
    setStep('upload');
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Container fluid className="vh-100 d-flex flex-column">
      {step !== 'login' && (
        <Navbar bg="light" expand="lg" className="justify-content-between">
          <Navbar.Brand>BuildGPT</Navbar.Brand>
          <Button variant="outline-secondary" size="sm" onClick={handleLogout}>
            Logout
          </Button>
        </Navbar>
      )}
      <Container className="d-flex justify-content-center align-items-center flex-grow-1 overflow-auto">
        {step === 'login' && <Login onPhoneSubmit={handlePhoneSubmit} />}
        {step === 'verification' && <Verification phone={phone} onVerified={handleVerified} />}
        {step === 'upload' && (
          <div className="text-center mb-3 w-100">
            <Upload onUpload={onUpload} isUploading={isUploading} fileName={fileName} numPages={numPages} showLogo={false} />
            {images.length > 0 && (
              <Thumbnails 
                images={images} 
                selectedImage={selectedImage} 
                handleClick={handleClick} 
                handleSubmit={handleSubmit}
              />
            )}
          </div>
        )}
        {step === 'computerVision' && <ComputerVision setStep={setStep} />}
        {step === 'convert2Json' && <Convert2Json />}
        {step === 'chat' && <Chat currentPage={currentPage} />}
      </Container>
    </Container>
  );
};

export default App;
