import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';

const Thumbnails = ({ images, selectedImage, handleClick, handleSubmit }) => {
  return (
    <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
      <div className="text-center mt-3">
        <Button variant="success" onClick={handleSubmit}>Submit</Button>
      </div>
      <Row className="mt-4">
        {images.map((image, index) => (
          <Col key={index} md={4} className="mb-4">
            <Card 
              onClick={() => handleClick(image)}
              className={`img-thumbnail shadow-sm ${selectedImage === image ? 'border border-primary' : ''}`}
              style={{ cursor: 'pointer', width: '100%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
            >
              <Card.Img 
                variant="top" 
                src={image.url} 
                alt={`PDF Page ${index + 1}`} 
                style={{ width: '100%', maxWidth: '400px', height: 'auto' }} 
              />
              {selectedImage === image && (
                <div 
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    background: 'rgba(255,255,255,0.8)',
                    borderRadius: '50%',
                    padding: '5px'
                  }}
                >
                  <i className="pi pi-check" style={{ fontSize: '24px', color: 'green' }}></i>
                </div>
              )}
            </Card>
            <div className="mt-2 text-center">
              <div>PDF: {image.pageSize.width.toFixed(2)} x {image.pageSize.height.toFixed(2)} inches</div>
              <div>Scale: {image.scale}</div>
              <div>Page Number: {image.pageNumber}</div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Thumbnails;
